import React, { memo } from 'react';
import { Button, Form, Input } from 'antd';
import { DragOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import useDragDrop from '../utils/useDragDrop';
import UploadImage from './FileInput/UploadImageOrVideo';
import callApi from '../Api/config';
import { InputProps } from './TextInput';
import { toastError, toastSuccess } from '../service/Toast';
import { failedMsg } from '../constants/messages';

import './style.scss';

const formItemLayout = {
    labelCol: {
        xs: {
            span: 4,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 8,
            offset: 4,
        },
        sm: {
            span: 8,
            offset: 4,
        },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        sm: {
            span: 8,
            offset: 8,
        },
    },
};

interface MultipleTextInputProps extends InputProps {
    form: any;
    entityName?: string;
    formHeader?: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    showAddButton?: boolean;
}

const MultipleTextInput = (props: MultipleTextInputProps) => {
    const { formInput, form, entityName, formHeader: fallbackFieldName, setLoading, index } = props;
    const { fieldType } = formInput;
    const { showAddButton = true } = fieldType;

    const fieldName = fieldType?.fieldName || fallbackFieldName || 'Field';

    const onDelete = async (entityName: string, id: number, remove: any, name: number) => {
        setLoading(true);

        const res: any = await callApi(`${entityName}/${id}`, 'DELETE');

        if (res?.status) {
            toastSuccess(`${fieldName} deleted successfully!`);
            remove(name);
        } else {
            toastError(`Oops, an error occured! ${fieldName} deletion failed. ${res?.data.error || failedMsg}`);
        }

        setLoading(false);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Form.List
                key={index}
                name={formInput.name}
                rules={[
                    {
                        validator: async (_, names) => {
                            if (!names || names.length < fieldType.minCount) {
                                return Promise.reject(
                                    new Error(`At least ${fieldType.minCount} ${fieldName} is required`),
                                );
                            }

                            if (Array.isArray(names) && names?.some((name: any) => !name)) {
                                return Promise.reject(new Error('Empty fields are not allowed'));
                            }
                        },
                    },
                ]}>
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map(({ key, name, ...restFields }, index) => (
                            <MemoisedInput
                                key={key}
                                index={index}
                                formInput={formInput}
                                {...restFields}
                                form={form}
                                entityName={entityName}
                                onDelete={onDelete}
                                remove={remove}
                                name={name}
                            />
                        ))}
                        {showAddButton && (
                            <Form.Item wrapperCol={{ offset: 8 }} labelCol={{ span: 4 }}>
                                <Button
                                    type='dashed'
                                    disabled={
                                        formInput.fieldType.maxCount
                                            ? fields.length >= formInput.fieldType.maxCount
                                            : false
                                    }
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}>
                                    {`Add ${formInput.tableMeta.title}`}
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        )}
                    </>
                )}
            </Form.List>
        </DndProvider>
    );
};

const InputContainer = (props: any) => {
    const { key, index, formInput, form, entityName, onDelete, remove, name, ...restFields } = props;
    const { fieldType, isValueKeyReadonly } = formInput;
    const { showMinusButton = true } = fieldType;

    const ref = useDragDrop(form, index, formInput);

    const imageKeysArray = Array.isArray(fieldType.imageKeys) ? fieldType.imageKeys : [fieldType.imageKeys];

    return (
        <div ref={fieldType.dragDrop && ref}>
            <Form.Item
                key={key}
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={
                    index === 0 ? <div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div> : ''
                }
                labelAlign='left'
                required={formInput.required}
                colon={formInput.required}>
                <div className='mti-container'>
                    <Form.Item
                        name={formInput.fieldType.valueKey ? [name, formInput.fieldType.valueKey] : name}
                        {...restFields}
                        noStyle
                        rules={[
                            {
                                required: formInput.required,
                                message: formInput.errorMsg,
                            },
                        ]}>
                        <Input placeholder={formInput.placeholder} disabled={isValueKeyReadonly} />
                    </Form.Item>
                    <div className='extraIcons'>
                        {showMinusButton && (
                            <MinusCircleOutlined
                                className='dynamic-delete-button'
                                onClick={() => {
                                    const fieldDetails = form.getFieldValue(formInput.name)[name];
                                    fieldDetails?.id &&
                                        entityName &&
                                        onDelete(entityName, fieldDetails.id, remove, name);
                                    // The following condition is for the offer cards on Homepage
                                    fieldDetails?.id && !entityName && remove(name);
                                    !fieldDetails?.id && remove(name);
                                }}
                            />
                        )}

                        {fieldType.dragDrop && <DragOutlined className='dragger' />}
                    </div>
                </div>
            </Form.Item>
            {fieldType.secondValueKey && (
                <Form.Item wrapperCol={{ span: 8, offset: 8 }} name={[name, fieldType.secondValueKey.key]}>
                    <Input placeholder={fieldType.secondValueKey.placeholder} />
                </Form.Item>
            )}
            {fieldType.imageKeys && (
                <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
                    <div
                        className='uploadContainer'
                        style={{ gridTemplateColumns: `repeat(${imageKeysArray.length}, 1fr)` }}>
                        {imageKeysArray.map((keyObject: { key: string; placeholder: string; fieldType: any }) => (
                            <Form.Item key={keyObject.key} name={[name, keyObject.key]}>
                                <UploadImage
                                    name={formInput.name}
                                    fieldValue={form.getFieldValue(formInput.name)}
                                    form={form}
                                    fieldType={keyObject.fieldType}
                                    nestedKey={keyObject.key}
                                    index={name}
                                    placeholder={keyObject.placeholder}
                                />
                            </Form.Item>
                        ))}
                    </div>
                </Form.Item>
            )}
        </div>
    );
};

// This component used to rerender even when not required because the reference for 'remove' function was changing. With the following check the component will not rerender when not required.
const MemoisedInput = memo(InputContainer, (prevProps, currentProps) =>
    Object.entries(prevProps).reduce((acc: boolean, [key, value]) => {
        if (!acc || key === 'remove') {
            return acc;
        }
        return Object.is(value, currentProps[key]);
    }, true),
);

export default MultipleTextInput;
