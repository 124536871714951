export const REGEX_URL = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const REGEX_MOBILE = /^[6-9]{1}[0-9]{9}$/;
export const REGEX_MIN_VALUE = /^[1-9][0-9]*$/;
export const REGEX_LESS_VALUE = /^[0-9][0-9]*$/;
export const REGEX_MAX_VALUE = /^(?:[0-9]|[1-9][0-9]|100)$/;
export const REGEX_PINCODE_VALUE = /^([1-9]{1}[0-9]{5})$/;
export const REGEX_NO_NUMBER = /^([1-9]{1}[0-9]{5})$/;
export const REGEX_PHONE_NUMBER = /^([1-9]{1}[0-9]{9})$/;
export const REGEX_AADHAAR = /^([1-9]{1}[0-9]{11})$/;
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{8,}$/;
export const REGEX_RATING = /^(?:[1-5](?:\.\d{1,1})?|4(?:\.(?:[0-4][0-9]|50?)?))$/;
export const REGEX_PASSWORD_LENGTH_LIMIT =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{8,12}$/;
