import { errorMsg } from '../constants/messages';

const TermsAndConditionFormData = [
    {
        title: 'Terms and Condition Details',
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'customTextEditor',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { TermsAndConditionFormData };
