/* eslint-disable no-console */
import { QUESTION_ENTITY, USERS } from '../constants/EntityName';

export const positionSort = (firstEntity: { position: number }, secondEntity: { position: number }) =>
    firstEntity.position - secondEntity.position;

export const getIncomingPayload = (entityName: string, apiData: any) => {
    switch (entityName) {
        case USERS: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'password') {
                    return { ...acc, [key]: '*****' };
                }

                return { ...acc, [key]: value };
            }, {});
        }
        case QUESTION_ENTITY: {
            const payload = Object.entries(apiData).reduce((acc: any, [key, value]: any) => {
                if (key === 'options') {
                    acc['options'] = value.map((item: any, index: any) => {
                        const obj: any = {};
                        obj.text = item.text;
                        obj.icon = [
                            {
                                response: { data: item.icon },
                                uid: `${index}`,
                                status: 'done',
                                thumbUrl: item.icon,
                                name: 'image',
                            },
                        ];

                        return obj;
                    });
                    return acc;
                }

                return { ...acc, [key]: value };
            }, {});

            return payload;
        }
    }

    const entityNamesArray = entityName?.split('/');
    const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];

    switch (`/${requiredEntityName}`) {
        default:
            return apiData;
    }
};
