import { useCallback, useEffect, useState } from 'react';
import { FormInstance, message, Upload } from 'antd';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DraggableUploadListItem from '../DraggableUploadListItem';

import { BASE_API_URL } from '../../../env';
import { getToken } from '../../../service/CookieManager';

import './entity.scss';

const token = getToken(`${BASE_API_URL}/utils/fileUpload`);

const { Dragger } = Upload;

interface IUploadImage {
    name: string;
    fieldValue?: any;
    fieldType: {
        accept?: string;
        maxCount?: number;
        allowImageCropping?: boolean;
        maxFileSize?: number;
        aspectRatio?: number;
    };
    shape?: 'rect' | 'round';
    form: FormInstance;
    nestedKey?: string;
    index?: any;
    placeholder?: string;
}

const getDefaultProps = (props: IUploadImage): any => ({
    name: 'displayImage',
    multiple: false,
    action: `${BASE_API_URL}homepage/upload`,
    headers: {
        // commented out for now
        Authorization: `Bearer ${token}`,
    },
    maxCount: props.fieldType.maxCount,
    listType: 'picture-card',
    accept: props.fieldType.accept,
});

/**
 * This component sets the form fields for file uploads for two different types of payload structure. In one case the file URL is simply added (string for single file and array of URLs for multiple files) in the provided key of an object. In another case the key for the object is needed but two additional paramters are needed. One is the index of the object in the array and other is the nestedKey which will contain the URL data
 * @param props is of type IUploadImage
 * @returns The relevant JSX
 */
const UploadImage = (props: IUploadImage) => {
    const { name, form, fieldValue, nestedKey, index, fieldType, placeholder } = props;
    // eslint-disable-next-line no-unused-vars
    const { maxCount, allowImageCropping, maxFileSize, aspectRatio = 1 } = fieldType;

    const [fileList, setFileList] = useState<any>();
    const [imageCount, setImageCount] = useState<number>(0);

    const imageProps = getDefaultProps(props);

    useEffect(() => {
        if (fieldValue) {
            if (typeof fieldValue === 'string') {
                setImageCount(1);
            } else {
                if (nestedKey) {
                    fieldValue[index]?.[nestedKey] ? setImageCount(1) : setImageCount(0);
                } else {
                    setImageCount(fieldValue.length);
                }
            }

            const getFileList = (value: any, nestedKey?: string) => {
                if (nestedKey) {
                    // filelist is being set as array even for single url.
                    const fileList = [];
                    const url = value?.[index]?.[nestedKey];

                    if (url)
                        fileList.push({
                            name: '',
                            status: 'done',
                            url,
                        });

                    return fileList;
                }

                return (Array.isArray(value) ? value : [value]).map((url: string) => ({
                    name: '',
                    status: 'done',
                    url,
                }));
            };

            const fileList = getFileList(fieldValue, nestedKey);
            setFileList(fileList);
        }
    }, [fieldValue, index, nestedKey]);

    const setUploadedValueInForm = (value: any) => {
        if (nestedKey) {
            const formFieldValue = form.getFieldValue(name);

            const newFormFieldValue = formFieldValue.map((obj: any, idx: any) => {
                if (idx === index) {
                    return { ...obj, [nestedKey]: value[0] };
                }

                return obj;
            });

            value.length === 1 && nestedKey && form.setFieldsValue({ [name]: newFormFieldValue });
        } else {
            if (value.length === 0) {
                form.setFieldsValue({ [name]: null });
            }

            if (value.length === 1) {
                form.setFieldsValue({ [name]: value[0] });
            }

            if (value.length > 1) {
                form.setFieldsValue({ [name]: value });
            }
        }
    };

    const beforeUpload = () => {
        if (imageCount == maxCount) {
            return message.error(`maximum limit to upload file is ${maxCount}`);
        } else {
            return true;
        }
    };

    const moveRow = useCallback(
        (dragIndex: any, hoverIndex: any) => {
            const dragRow = fileList[dragIndex];
            setFileList(
                update(fileList, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );

            const rearrangedURLs = update(fileList, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            });

            form.setFieldsValue({
                [name]: rearrangedURLs.map((obj: any) => obj.url || obj.response.data),
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fileList],
    );

    const onChange = (info: any) => {
        const { fileList, file } = info;
        const { status, size } = file;

        switch (status) {
            case 'uploading': {
                const fileSizeCheck = (size: number, allowedSize?: number) => {
                    if (!allowedSize) return true;

                    return size / 1024 < allowedSize;
                };

                const isUploadAllowed = fileSizeCheck(size, maxFileSize);

                if (isUploadAllowed) {
                    setImageCount(fileList.length);
                    setFileList(fileList);
                } else {
                    message.error(`${info.file.name} size is large than the allowed size.`);
                    // console.error('lol', status);
                }
                break;
            }

            case 'removed': {
                setImageCount(fileList.length);
                setFileList(fileList);
                break;
            }

            case 'done': {
                setImageCount(fileList.length);
                setFileList(fileList);
                message.success(`${info.file.name} file uploaded successfully.`);
                const value = fileList.map((value: any) => (value.url ? value.url : value.response.data));
                setUploadedValueInForm(value);
                break;
            }

            case 'error': {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const onRemove = (file: any) => {
        if (nestedKey) {
            const formFieldValue = form.getFieldValue(name);

            const newFormFieldValue = formFieldValue.map((obj: any, idx: any) => {
                if (idx === index) {
                    return { ...obj, [nestedKey]: null };
                }

                return obj;
            });
            form.setFieldsValue({ [name]: newFormFieldValue });
        } else {
            const filterFileList = fileList
                .filter((ele: any) => {
                    const check = fieldValue ? ele.url : ele.response.data;
                    return check !== file.url;
                })
                .map((ele: any) => ele.url);
            setUploadedValueInForm(filterFileList);
        }
    };

    const onPreview = async (file: any) => {
        const { response, url } = file;

        if (url) {
            const image = new Image();
            image.src = url;
            window.open(url);
        } else {
            const url = response.data;

            const image = new Image();
            image.src = url;
            window.open(url);
        }
    };

    return allowImageCropping ? (
        <DndProvider backend={HTML5Backend}>
            <Dragger
                fileList={fileList}
                {...imageProps}
                onChange={onChange}
                itemRender={(originNode, file, currFileList) => (
                    <DraggableUploadListItem
                        originNode={originNode}
                        file={file}
                        fileList={currFileList}
                        moveRow={moveRow}
                    />
                )}
                onRemove={onRemove}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                style={{ display: imageCount == maxCount && 'none' }}>
                <div
                    style={{
                        display: 'grid',
                        placeItems: 'center',
                        height: '102px',
                        lineHeight: '17px',
                        background: '#F8FAFB',
                        color: '#637F92',
                    }}>
                    <span>{placeholder || 'Click to upload media'}</span>
                    {maxFileSize && <span>{`Maximum file size allowed: ${maxFileSize} KB`}</span>}
                </div>
            </Dragger>
        </DndProvider>
    ) : (
        <Dragger
            fileList={fileList}
            {...imageProps}
            onChange={onChange}
            onRemove={onRemove}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            style={{ display: imageCount == maxCount && 'none' }}>
            <div style={{ height: '117px', lineHeight: '117px', background: '#F8FAFB', color: '#637F92' }}>
                Click to upload media
            </div>
        </Dragger>
    );
};

UploadImage.defaultProps = {
    allowImageCropping: true,
};

export default UploadImage;
