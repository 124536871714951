import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { InputProps } from './TextInput';

const { Option } = Select;

import './style.scss';

const MultipleTwinInput = (props: InputProps) => {
    const { formInput, index } = props;

    const {
        fieldType: { options },
    } = formInput;

    return (
        <Form.List key={index} name={formInput.name}>
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map(({ key, name, ...restField }, index) => (
                        <Form.Item
                            key={key}
                            wrapperCol={{ span: 8, offset: 4 }}
                            labelCol={{ span: 4 }}
                            label={
                                index === 0 ? (
                                    <div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>
                                ) : (
                                    <div></div>
                                )
                            }
                            labelAlign='left'
                            colon={false}>
                            <div
                                style={{
                                    display: 'flex',
                                    columnGap: '8px',
                                }}>
                                {formInput.fieldType.inputOption === 'option' ? (
                                    <Form.Item
                                        style={{ marginBottom: '0px', width: '100%' }}
                                        {...restField}
                                        name={[name, 'id']}
                                        rules={[{ required: true, message: 'Missing name' }]}>
                                        <Select
                                            placeholder={formInput.placeholder}
                                            disabled={formInput.readonly}
                                            allowClear
                                            showSearch
                                            optionFilterProp='children'
                                            style={{ width: '100%' }}>
                                            {options.map((obj: any, index: number) => (
                                                <Option key={index} value={obj.value} name={obj.name}>
                                                    {obj.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        style={{ marginBottom: '0px', width: '100%' }}
                                        {...restField}
                                        name={[name, 'key']}
                                        rules={[{ required: true, message: 'Missing name' }]}>
                                        <Input
                                            placeholder={'key'}
                                            disabled={formInput.readonly}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    style={{ marginBottom: '0px', width: '100%' }}
                                    {...restField}
                                    name={[name, 'value']}
                                    rules={[{ required: true, message: 'Missing quantity' }]}>
                                    <Input
                                        placeholder='value'
                                        disabled={formInput.readonly}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                {!formInput.readonly && (
                                    <MinusCircleOutlined
                                        className='dynamic-delete-button offset'
                                        onClick={() => remove(name)}
                                    />
                                )}
                            </div>
                        </Form.Item>
                    ))}
                    {!formInput.readonly ? (
                        <Form.Item
                            wrapperCol={{ offset: 8 }}
                            labelCol={{ span: 4 }}
                            style={{ position: 'relative' }}
                            required>
                            <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                                Add {formInput.label}
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    ) : null}
                </>
            )}
        </Form.List>
    );
};

export default MultipleTwinInput;
