import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Sidebar from '../../components/SideBar';
import { AppRoutes, entityArray } from '../../constants/AppConstant';

import Users from '../UserList';
import FaqModule from '../FaqModule';

import QuestionModule from '../QuestionModule';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsAndCondition from '../TermsAndCondition';
import './styles.scss';

const Landing = () => {
    const [moduleList] = useState<any>(entityArray);

    return (
        <Router>
            <div className='landing-box'>
                <Sidebar title='MMAI' entityArr={moduleList} />
                <div className='landing-box-content'>
                    <Switch>
                        <Route exact path='/' component={Users} />
                        <Route exact path={AppRoutes.Users} component={Users} />

                        <Route exact path={`${AppRoutes.Users}`}>
                            <Users />
                        </Route>

                        <Route exact path={`${AppRoutes.Faq}`}>
                            <FaqModule />
                        </Route>
                        <Route exact path={`${AppRoutes.Question}`}>
                            <QuestionModule />
                        </Route>
                        <Route exact path={`${AppRoutes.PrivacyPolicy}`}>
                            <PrivacyPolicy />
                        </Route>
                        <Route exact path={`${AppRoutes.TermsAndCondition}`}>
                            <TermsAndCondition />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
};

export default Landing;
