/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Image } from 'antd';

import Lock from '../../assets/svg/Lock.svg';
import Hide from '../../assets/svg/Hide.svg';
import Show from '../../assets/svg/eye.svg';
import { toastError, toastSuccess } from '../../service/Toast';
import callApi from '../../Api/config';
import { RESET_PASSWORD } from '../../constants/EntityName';
import { REGEX_PASSWORD_LENGTH_LIMIT } from '../../utils/Regexp/Regex';

import './style.scss';

const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const token = queryParams.get('token');

    useEffect(() => {
        if (!token) {
            toastError('Invalid or missing token.');
        }
    }, [token]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const payload = {
            password,
        };

        if (!password || !confirmPassword) {
            toastError('Please enter your new password.');
        }
        if (password !== confirmPassword) {
            toastError('Passwords do not match.');
        }
        if (!REGEX_PASSWORD_LENGTH_LIMIT.test(password) || !REGEX_PASSWORD_LENGTH_LIMIT.test(confirmPassword)) {
            toastError('Password or Confirm Password does not meet the required criteria.');
            return;
        }
        try {
            const res = await callApi(RESET_PASSWORD, 'PATCH', payload, { token });
            if (res?.data?.statusCode === 200) {
                toastSuccess('Password changed successfully.');
                setPassword('');
                setConfirmPassword('');
            } else {
                toastError(res?.data?.message);
            }
        } catch (error) {
            toastError('An error occurred while resetting your password.');
        }
    };

    return (
        <div className='reset-password'>
            <div className='container'>
                <div>
                    <h2>Reset Password</h2>
                    <div className='input-group'>
                        <label htmlFor='password'>Password</label>
                        <div className='relative'>
                            <div className='lock-absolute'>
                                <Image src={Lock} alt='password' width={20} height={20} />
                            </div>
                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                name='password'
                                id='password'
                                value={password}
                                required
                                placeholder='Password'
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div
                                className='hide-absolute'
                                onClick={() => setPasswordVisible(!passwordVisible)}
                                style={{ cursor: 'pointer' }}>
                                <img
                                    src={passwordVisible ? Show : Hide}
                                    alt='password visibility toggle'
                                    width={20}
                                    height={20}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='input-group'>
                        <label htmlFor='confirm-password'>Confirm Password</label>
                        <div className='relative'>
                            <div className='lock-absolute'>
                                <Image src={Lock} alt='password' width={20} height={20} />
                            </div>
                            <input
                                type={confirmPasswordVisible ? 'text' : 'password'}
                                name='confirm-password'
                                id='confirm-password'
                                value={confirmPassword}
                                required
                                placeholder='Confirm Password'
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <div
                                className='hide-absolute'
                                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                style={{ cursor: 'pointer' }}>
                                <img
                                    src={confirmPasswordVisible ? Show : Hide}
                                    alt='password visibility toggle'
                                    width={20}
                                    height={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Button id='continueBtn' onClick={handleSubmit}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
