import { errorMsg } from '../constants/messages';

const FaqFormData = [
    {
        title: 'Users Details',
        name: 'question',
        label: 'Question',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Question', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'answer',
        label: 'Answer',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Answer' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { FaqFormData };
