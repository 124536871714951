// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center; }

.loader {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .loader p {
    margin-top: 16px;
    font-size: 18px;
    color: #333; }

.verified-text {
  font-size: 24px;
  color: #4caf50; }
`, "",{"version":3,"sources":["webpack://./src/pages/Signup/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,kBAAkB,EAAA;;AAGpB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;EAHrB;IAMI,gBAAgB;IAChB,eAAe;IACf,WAAW,EAAA;;AAIf;EACE,eAAe;EACf,cAAc,EAAA","sourcesContent":[".sign-up-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    text-align: center;\n  }\n  \n  .loader {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  \n    p {\n      margin-top: 16px;\n      font-size: 18px;\n      color: #333;\n    }\n  }\n  \n  .verified-text {\n    font-size: 24px;\n    color: #4caf50;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
