import { errorMsg } from '../constants/messages';

const QuestionFormData = [
    {
        title: 'Question Details',
        name: 'questionText',
        label: 'Question',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Question', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'options',
        label: 'Options',
        fieldType: {
            name: 'multiImageUpload',
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Options' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'isRequired',
        label: 'Is Required',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Is Required' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'inputType',
        label: 'Input Type',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Single Select', value: 'Single-Select' },
                { name: 'Multi Select', value: 'Multi Select' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Input Type' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isActive',
        label: 'Is Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Status' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { QuestionFormData };
