/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Upload, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { BASE_API_URL } from '../../env';

import './style.scss';

const MultipleImageUpload = (props: any) => {
    const { formInput, index, form, isCreateMode } = props;
    const [fileList, setFileList] = useState<any[]>([]);
    const formFieldValue = form.getFieldValue(formInput.name) || [];
    const [isDeleted, setIsDeleted] = useState<boolean>(false);

    const onChange = (info: any, fieldKey: number) => {
        const { fileList: newFileList, file } = info;
        const { status, size } = file;

        switch (status) {
            case 'uploading': {
                const fileSizeCheck = (size: number, allowedSize?: number) => {
                    if (!allowedSize) return true;
                    return size / 1024 < allowedSize;
                };

                const isUploadAllowed = fileSizeCheck(size, formInput.fieldType.maxFileSize);

                if (isUploadAllowed) {
                    const updatedFileList = [...fileList];
                    updatedFileList[fieldKey] = newFileList;
                    setFileList(updatedFileList);
                }
                break;
            }

            case 'removed': {
                setIsDeleted(true);
                const updatedFileList = [...fileList];
                updatedFileList[fieldKey] = null;
                setFileList(() => updatedFileList);
                break;
            }

            case 'done': {
                const updatedFileList = [...fileList];
                const res = newFileList.map((value: any) => ({
                    status: 'done',
                    url: value.url || value.response.data,
                    name: value.name,
                }));
                updatedFileList[fieldKey] = res;
                setFileList(updatedFileList);
                message.success(`${info.file.name} file uploaded successfully.`);
                break;
            }

            case 'error': {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const onPreview = async (file: any) => {
        let src = file.response.data as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none', width: '400px' }} type='button'>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload media</div>
        </button>
    );

    // Initialize file list with backend data
    const setFileValue = () => {
        if (formFieldValue && formFieldValue.length > 0) {
            const updatedFileList = formFieldValue.map((obj: any, index: number) => {
                if (obj?.icon) {
                    return {
                        uid: `${index}`,
                        status: 'done',
                        thumbUrl: obj.icon,
                        name: 'image',
                        response: { data: obj.icon },
                    };
                }
                return [];
            });
            setFileList(updatedFileList);
        }
    };

    useEffect(() => {
        if (!isCreateMode && !isDeleted) {
            setFileValue();
        }
    }, [formFieldValue]);

    return (
        <Form.List key={index} name={formInput.name}>
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields?.map(({ key, name, ...restField }, fieldIndex) => (
                        <Form.Item
                            key={key}
                            wrapperCol={{ span: 8, offset: 4 }}
                            labelCol={{ span: 4 }}
                            label={fieldIndex === 0 ? <div>{formInput.label}</div> : <div></div>}
                            labelAlign='left'
                            colon={true}>
                            <div style={{ display: 'flex', columnGap: '8px' }} className='imageUploadContainer'>
                                <Form.Item
                                    key={fieldIndex}
                                    style={{ marginBottom: '0px', width: '100%' }}
                                    {...restField}
                                    name={[name, 'icon']}
                                    valuePropName='fileList'
                                    getValueFromEvent={(e) => {
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        return e && e.fileList;
                                    }}
                                    rules={[{ required: false, message: 'Please upload an image' }]}>
                                    <Upload
                                        name='iconImage'
                                        listType='picture-card'
                                        fileList={fileList[fieldIndex] || []}
                                        action={`${BASE_API_URL}admin/upload/icon`}
                                        onChange={(info) => onChange(info, fieldIndex)}
                                        onPreview={onPreview}>
                                        {fileList[fieldIndex] ? null : uploadButton}
                                    </Upload>
                                </Form.Item>
                                {!formInput.readonly && (
                                    <MinusCircleOutlined
                                        className='dynamic-delete-button offset'
                                        style={{ marginRight: '-50px', marginTop: '30px' }}
                                        onClick={() => remove(name)}
                                    />
                                )}
                            </div>
                            <Form.Item
                                key={fieldIndex}
                                style={{ marginBottom: '0px', width: '100%' }}
                                {...restField}
                                name={[name, 'text']}
                                getValueFromEvent={(e) => e.target.value}
                                rules={[{ required: true, message: 'Please Enter image info' }]}>
                                <Input placeholder='Enter image info' />
                            </Form.Item>
                        </Form.Item>
                    ))}
                    {!formInput.readonly && (
                        <Form.Item
                            wrapperCol={{ offset: 8 }}
                            labelCol={{ span: 4 }}
                            style={{ position: 'relative' }}
                            required>
                            <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                                Add {formInput.label}
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    )}
                </>
            )}
        </Form.List>
    );
};

export default MultipleImageUpload;
