/* eslint-disable no-unused-vars */
export enum AppRoutes {
    Products = '/products',
    Users = '/admin/users',
    OrderList = '/orderList',
    Faq = '/faq',
    Question = '/questions',
    PrivacyPolicy = '/privacy-policy',
    TermsAndCondition = '/terms-condition',
}
const entityArray = [
    { key: 'item-1', entityName: 'Users', entityUrl: AppRoutes.Users },
    { key: 'item-2', entityName: 'FAQs', entityUrl: AppRoutes.Faq },
    { key: 'item-3', entityName: 'Getting know Questions', entityUrl: AppRoutes.Question },
    // { key: 'item-4', entityName: 'Privacy Policy', entityUrl: AppRoutes.PrivacyPolicy },
    // { key: 'item-5', entityName: 'Terms And Condition', entityUrl: AppRoutes.TermsAndCondition },
];

export { entityArray };
